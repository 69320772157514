.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.makeStyles-drawer-9 {
  width: 266px!important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.react-daterange-picker__wrapper {
  border: unset!important;
  border-bottom: solid 1px!important;
}
abbr[title]{
  text-decoration: none !important;
}
.react-calendar {
  border-radius: 15px!important;
}
.react-daterange-picker__button {
  outline: none;
}
.react-daterange-picker__range-divider {
  margin-left: 10px;
  margin-right: 10px;
}
.react-daterange-picker__inputGroup__input {
  outline: none;
}
.react-daterange-picker__inputGroup__input {
  pointer-events: none!important;
}

/*
The StripeElement class is applied to the Element container by default.
More info: https://stripe.com/docs/stripe-js/reference#element-options
*/

.StripeElement,
.StripeElementIdeal {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 500px;
  padding: 10px 14px;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
  rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border-radius: 4px;
  background: white;
}

.StripeElement--focus,
.StripeElementIdeal--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
  rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.StripeElement.loading {
  height: 41.6px;
  opacity: 0.6;
}

.StripeElementIdeal {
  padding: 0;
}

